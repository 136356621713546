import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms'
import MainContent from '../components/mainContent';
import Jumbo from '../components/jumbo';

import Layout from '../components/Layout';

const ContactPage = ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.datoCmsContactPage.seoMetaTags} />

    <Jumbo
      image={data.datoCmsContactPage.headerImage.fluid}
      title={data.datoCmsContactPage.mainHeading}
      subTitle={data.datoCmsContactPage.subHeading}
    />

    <MainContent content={data.datoCmsContactPage.mainText} />
  </Layout>
);

export default ContactPage;

export const query = graphql`
  query ContactPageQuery {
    datoCmsContactPage {
      mainHeading
      subHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      mainText
      headerImage {
        fluid(maxWidth: 941, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
